import React from "react";
import banner1 from "@/assets/images/banner1.jpg";
import banner2 from "@/assets/images/banner2.jpg";
import banner3 from "@/assets/images/banner3.jpg";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { useTranslation } from "react-i18next";

export const ProductPresentation = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-fit flex justify-center">
      <div className="w-full gap-10 h-full flex flex-col justify-around py-16 px-2 md:px-0 md:max-w-[1200px]">
        <div className="flex flex-col md:flex-row gap-3 md:gap-12 h-1/4">
          <div className="w-full md:w-1/2 h-full">
            <img className="w-full h-full object-cover" src={banner1} />
          </div>
          <div className="flex flex-1 flex-col justify-center">
            <div className="lg:max-h-[100px]">
              <p className="leading-[32px]">
                {t(`homepage:productPresentation.card1.content`)}
              </p>
            </div>
            <div className="flex items-center gap-3">
              <a
                href="/products"
                className="border-b-2 border-secondary py-2 px-2 flex gap-2 items-center"
              >
                <h4 className="text-xl">
                  {t(`homepage:productPresentation.card1.btn`)}
                </h4>
                <HiOutlineArrowLongRight size={30} />
              </a>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row-reverse gap-3 md:gap-12 h-1/4">
          <div className="w-full md:w-1/2 h-full">
            <img className="w-full h-full object-cover" src={banner2} />
          </div>
          <div className="flex flex-1 flex-col justify-center">
            <div className="lg:max-h-[100px]">
              <p className="leading-[32px]">
                {t(`homepage:productPresentation.card2.content`)}{" "}
              </p>
            </div>
            <div className="flex items-center gap-3">
              <a
                href="/inspiration_gallery"
                className="border-b-2 border-secondary py-2 px-2 flex gap-2 items-center"
              >
                <h4 className="text-xl">
                  {t(`homepage:productPresentation.card2.btn`)}
                </h4>
                <HiOutlineArrowLongRight size={30} />
              </a>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-3 md:gap-12 h-1/4">
          <div className="w-full md:w-1/2 h-full">
            <img className="w-full h-full object-cover" src={banner3} />
          </div>
          <div className="flex flex-1 flex-col justify-center">
            <div className="lg:max-h-[100px]">
              <p className="leading-[32px]">
                {t(`homepage:productPresentation.card3.content`)}
              </p>
            </div>
            <div className="flex items-center gap-3">
              <a
                href="/video_inspiration"
                className="border-b-2 border-secondary py-2 px-2 flex gap-2 items-center"
              >
                <h4 className="text-xl">
                  {t(`homepage:productPresentation.card3.btn`)}
                </h4>
                <HiOutlineArrowLongRight size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
