import React from "react";
import { ProductCard } from "./ProductCard";
import { productCards } from "@/contains";
import { SlArrowUp } from "react-icons/sl";
import { useTranslation } from "react-i18next";

export const FeatureSection = ():JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="w-full h-fit flex justify-center py-8">
            <div className="w-[1200px] h-full flex flex-col justify-center items-center">
                <div className="flex justify-between flex-col lg:flex-row gap-3 lg:gap-0 px-2 sm:px-0">
                    {productCards.map((card, index) => (
                        <ProductCard key={index} {...card}/>
                    ))}
                </div>
                <div className="flex flex-col items-center text-center mt-10">
                    <div className="text-black">
                        <div className="flex justify-center">
                            <SlArrowUp size={40}/> 
                        </div>
                        <h1 className="text-3xl">Andi Quartz</h1>
                        <p className="text-2xl mt-8">{t("homepage:featureSection")}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}