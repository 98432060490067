import bg_banners from "@/assets/images/bg.jpg";
import { useTranslation } from "react-i18next";
import { BANNER_LIST } from "@/contains";

export const BannerLists = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full sm:h-[300px] flex justify-center relative px-2">
      <div className="absolute top-0 left-0 bottom-0 right-0">
        <img className="w-full h-full object-cover" src={bg_banners} />
      </div>
      <div className="w-[92%] mx-[4%] md:mx-[auto] md:w-[1200px] py-8 h-full flex justify-between items-center flex-col sm:flex-row gap-3">
        {BANNER_LIST.map((item, index) => (
          <>
            <div
              className="relative w-full h-[210px] sm:h-[210px] flex justify-center items-center cursor-pointer"
              key={index}
            >
              <div className="absolute top-0 right-0 bottom-0 left-0 rounded-2xl overflow-hidden">
                <img src={item.imgSrc} className="w-full h-full opacity-80" />
              </div>
              <div className="z-10 text-white text-center">
                <h2 className="text-3xl font-normal">
                  {t(`homepage:bannerList.banner${index + 1}.title`)}
                </h2>
                <p className="text-base mt-4">
                  {t(`homepage:bannerList.banner${index + 1}.content`)}
                </p>
              </div>
              <a
                href={item.link}
                className=" absolute w-full h-full top-0 left-0 rounded-2xl bg-black opacity-0 z-9 transition-opacity duration-300 hover:opacity-40 "
              ></a>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};
