import React from "react";
import banner1 from "@/assets/images/banner1.jpg"
import banner2 from "@/assets/images/banner2.jpg"
import banner3 from "@/assets/images/banner3.jpg"

import Slider from "react-slick";
import "@/assets/css/imgSliders.css"
const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dotts: true,
    appendDots: (dots: any) => (
        <div className="custom-dots">
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
      )
}
export const Banner = ():JSX.Element => {
    return (
        <div className="w-full">
            <Slider {...settings}>
                <img src={banner1}/>
                <img src={banner2}/>
                <img src={banner3}/>
            </Slider>
        </div>
    )
}