import React from "react";
import "@/assets/css/sliders.css";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { ISliders } from "@/modules/hompage/types";
const settings = {
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
};
interface ISlidersProps {
  sliders: ISliders[];
}

export const Sliders = ({ sliders }: ISlidersProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-fit flex justify-center">
      <div className="w-[1200px] h-full flex flex-col items-center justify-around gap-14">
        <p className="sm:w-[945px] w-[300px] text-center break-words text-[22px]">
          {t(`homepage:slider.content`)}
        </p>
        <div className="w-full flex justify-between items-center">
          <div className="text-[#99999b]">
            <SlArrowLeft size={30} />
          </div>
          <div className="w-[1050px]">
            <Slider {...settings}>
              {sliders.map((slider, index) => (
                <a
                  href={`/products/${slider.id}`}
                  className="w-[210px] h-[320px] max-w-full max-h-full px-2"
                  key={index}
                >
                  <img
                    className="w-full h-full object-cover"
                    src={slider.imgSrc}
                  />
                </a>
              ))}
            </Slider>
          </div>
          <div className="text-[#99999b]">
            <SlArrowRight size={30} />
          </div>
        </div>
        <a href="/products">
          <div className="flex items-center gap-3 border-b-2 border-secondary py-2 px-2">
            <h4 className="text-xl">{t(`homepage:slider.btn`)}</h4>
            <HiOutlineArrowLongRight size={30} />
          </div>
        </a>
      </div>
    </div>
  );
};
